'use strict';
angular
  .module('vimeoEmbed', [])
  .directive('vimeoVideo', [
    'VimeoService',
    function(e) {
      return {
        restrict: 'EA',
        replace: !0,
        scope: {
          videoId: '=',
          videoUrl: '=',
          playerOpts: '=',
          playerHeight: '=',
          playerWidth: '=',
          api: '=',
        },
        link: function(i, r, o) {
          var t = o.playerId || r[0].id;
          r[0].id = t;
          var n = i.videoId ? 'https://vimeo.com/' + i.videoId : i.videoUrl,
            a =
              '?url=' +
              encodeURIComponent(n) +
              '&callback=JSON_CALLBACK&player_id=' +
              t,
            d = i.playerOpts || null;
          if (
            (i.playerWidth && (a += '&width=' + i.playerWidth),
            i.playerHeight && (a += '&height=' + i.playerHeight),
            i.api && (a += '&api=1'),
            d)
          )
            for (var l in d) a += '&' + l + '=' + d[l];
          e.oEmbed(a).then(
            function(e) {
              r.html(e.html);
            },
            function(e) {
              r.html('<div>' + e + '</div>');
            }
          );
        },
      };
    },
  ])
  .factory('VimeoService', [
    '$q',
    '$http',
    function(e, i) {
      var r = 'https://www.vimeo.com/api/oembed.json';
      return {
        oEmbed: function(o) {
          var t = e.defer();
          return (
            i
              .jsonp(r + o)
              .success(function(e) {
                t.resolve(e);
              })
              .error(function() {
                t.reject('Oops! It looks like there was an error!');
              }),
            t.promise
          );
        },
      };
    },
  ]);
