/*! angular-swipe.min.js 15-07-2015 */
!(function(a, b, c) {
  'use strict';
  function d(a, c, d, f) {
    e.directive(a, [
      '$parse',
      'swipe',
      function(e, g) {
        var h = 75,
          i = 0.3,
          j = 30;
        return function(k, l, m) {
          function n(a) {
            if (!o || !p) return !1;
            var b = (a.y - o.y) * c,
              e = (a.x - o.x) * c;
            return d
              ? Math.abs(e) < h && b > 0 && b > j && Math.abs(e) / b < i
              : Math.abs(b) < h && e > 0 && e > j && Math.abs(b) / e < i;
          }
          var o,
            p,
            q = e(m[a]),
            r = ['touch'];
          b.isDefined(m.ngSwipeDisableMouse) || r.push('mouse'),
            g.bind(
              l,
              {
                start: function(a, b) {
                  var c = b.target.getAttribute('class');
                  d &&
                    (!c || (c && null === c.match('noPreventDefault'))) &&
                    b.preventDefault(),
                    (o = a),
                    (p = !0);
                },
                cancel: function() {
                  p = !1;
                },
                end: function(a, b) {
                  n(a) &&
                    k.$apply(function() {
                      l.triggerHandler(f), q(k, { $event: b });
                    });
                },
              },
              r
            );
        };
      },
    ]);
  }
  var e = b.module('swipe', []);
  e.factory('swipe', [
    function() {
      function a(a) {
        var b = a.originalEvent || a,
          c = b.touches && b.touches.length ? b.touches : [b],
          d = (b.changedTouches && b.changedTouches[0]) || c[0];
        return { x: d.clientX, y: d.clientY };
      }
      function c(a, c) {
        var d = [];
        return (
          b.forEach(a, function(a) {
            var b = f[a][c];
            b && d.push(b);
          }),
          d.join(' ')
        );
      }
      var d = 40,
        e = 0.3,
        f = {
          mouse: { start: 'mousedown', move: 'mousemove', end: 'mouseup' },
          touch: {
            start: 'touchstart',
            move: 'touchmove',
            end: 'touchend',
            cancel: 'touchcancel',
          },
        };
      return {
        bind: function(b, f, g) {
          var h,
            i,
            j,
            k,
            l = !1,
            m = !1,
            n = !0;
          (g = g || ['mouse', 'touch']),
            b.on(c(g, 'start'), function(b) {
              (j = a(b)),
                (l = !0),
                (h = 0),
                (i = 0),
                (m = !1),
                (n = !0),
                (k = j),
                f.start && f.start(j, b);
            }),
            b.on(c(g, 'cancel'), function(a) {
              (l = !1), f.cancel && f.cancel(a);
            }),
            b.on(c(g, 'move'), function(b) {
              if (l && j) {
                var c = a(b);
                if (
                  ((h += Math.abs(c.x - k.x)),
                  (i += Math.abs(c.y - k.y)),
                  (k = c),
                  !(d > h && d > i))
                ) {
                  if (!m) {
                    var g, o, p;
                    (g = Math.abs(c.x - j.x)),
                      (o = Math.abs(c.y - j.y)),
                      (p = o / g),
                      e > p ? (b.preventDefault(), (n = !1)) : (n = !0),
                      (m = !0);
                  }
                  (b.isVertical = n), f.move && f.move(c, b);
                }
              }
            }),
            b.on(c(g, 'end'), function(b) {
              l && ((b.isVertical = n), (l = !1), f.end && f.end(a(b), b));
            });
        },
      };
    },
  ]);
  try {
    b.module('ngTouch');
  } catch (f) {
    d('ngSwipeLeft', -1, !1, 'swipeleft'),
      d('ngSwipeRight', 1, !1, 'swiperight');
  }
  d('ngSwipeUp', -1, !0, 'swipeup'), d('ngSwipeDown', 1, !0, 'swipedown');
})(window, window.angular);
