/**
 * [jQuery-appear]{@link https://github.com/emn178/jquery-appear}
 *
 * @version 0.2.6
 * @author Yi-Cyuan Chen [emn178@gmail.com]
 * @copyright Yi-Cyuan Chen 2014-2016
 * @license MIT
 */
(function(c, n, k) {
  function u() {
    var a = c(this),
      b;
    if ((b = a.is(':visible')))
      (b = this.getBoundingClientRect()),
        (b =
          ((0 <= b.top && b.top <= v) || (0 <= b.bottom && b.bottom <= v)) &&
          ((0 <= b.left && b.left <= w) || (0 <= b.right && b.right <= w)));
    b
      ? (a.trigger(p), b != a.data('jquery-appear') && a.trigger(q))
      : b != a.data('jquery-appear') && a.trigger(r);
    a.data('jquery-appear', b);
  }
  function x() {
    v = n.innerHeight || k.documentElement.clientHeight;
    w = n.innerWidth || k.documentElement.clientWidth;
    l();
  }
  function t() {
    c(this)
      .find(':jquery-appear')
      .each(u);
  }
  function y() {
    var a = c(this),
      b;
    a.data('jquery-appear-scroller')
      ? (b = !1)
      : ((b = a.css('overflow')),
        'scroll' != b && 'auto' != b
          ? (b = !1)
          : (a.data('jquery-appear-scroller', 1),
            a.bind('scroll', t),
            (b = !0)));
    var f;
    z || a.data('jquery-appear-display')
      ? (f = void 0)
      : 'none' != a.css('display')
      ? (f = void 0)
      : (a.data('jquery-appear-display', 1), a._bindShow(t), (f = !0));
    b | f &&
      !a.data('jquery-appear-watch') &&
      (a.data('jquery-appear-watch', 1), (m = m.add(a)));
  }
  function D() {
    var a = c(this);
    a.data('jquery-appear-watch') &&
      0 === a.find(':jquery-appear').length &&
      (a
        .removeData('jquery-appear-scroller')
        .removeData('jquery-appear-display')
        .removeData('jquery-appear-watch'),
      a.unbind('scroll', t)._unbindShow(t));
  }
  function E(a) {
    var b = c(this);
    b.is(':jquery-appear') ||
      (A ||
        ((A = !0),
        x(),
        c(k).ready(function() {
          c(n)
            .on('resize', x)
            .on('scroll', l);
          c(k.body).on(
            'animationend webkitAnimationEnd oAnimationEnd transitionend webkitTransitionEnd oTransitionEnd',
            l
          );
        }),
        z &&
          new MutationObserver(l).observe(k, {
            attributes: !0,
            childList: !0,
            characterData: !0,
            subtree: !0,
          })),
      b.data('jquery-appear', !1),
      b.parents().each(y),
      setTimeout(function() {
        u.call(b[0]);
      }, 1),
      (h = h.add(this)));
  }
  function F(a) {
    var b = c(this);
    setTimeout(function() {
      for (
        var a = c._data(b[0], 'events') || {}, d = !1, e = 0;
        e < g.length;
        ++e
      )
        if (a[g[e]]) {
          d = !0;
          break;
        }
      d &&
        (b.removeData('jquery-appear'),
        (m = m.filter(':jquery-appear-watch')),
        m.each(D));
    }, 1);
  }
  function B() {
    for (var a = 0; a < g.length; ++a)
      c.event.special[g[a]] = { add: E, remove: F };
  }
  var q = 'appear',
    p = 'appearing',
    r = 'disappear',
    g = [q, p, r],
    z = void 0 !== n.MutationObserver,
    v,
    w,
    A = !1,
    h = c(),
    m = c();
  c.expr[':']['jquery-appear'] = function(a) {
    return void 0 !== c(a).data('jquery-appear');
  };
  c.expr[':']['jquery-appear-watch'] = function(a) {
    return void 0 !== c(a).data('jquery-appear-watch');
  };
  var l = (function(a) {
    var b = 0,
      c;
    return function() {
      var d = this,
        e = arguments,
        C = function() {
          b = new Date();
          a.apply(d, e);
        };
      c && (clearTimeout(c), (c = null));
      var g = new Date() - b;
      10 < g ? C() : (c = setTimeout(C, 10 - g));
    };
  })(function() {
    h = h.filter(':jquery-appear');
    h.each(u);
  });
  c.appear = {
    check: l,
    refresh: function(a) {
      (void 0 === a ? h : c(a)).each(function() {
        var a = c(this);
        a.is(':jquery-appear') && a.parents().each(y);
      });
    },
    setEventPrefix: function(a) {
      for (var b = 0; b < g.length; ++b) delete c.event.special[g[b]];
      q = a + 'appear';
      p = a + 'appearing';
      r = a + 'disappear';
      g = [q, p, r];
      B();
    },
  };
  B();
  (function() {
    function a() {
      var a = c(this),
        b = 'none' != a.css('display');
      a.data('jquery-appear-jquery-appear-show') != b &&
        (a.data('jquery-appear-jquery-appear-show', b),
        b && a.trigger('jquery-appear-show'));
    }
    function b() {
      e = e.filter(':jquery-appear-jquery-appear-show');
      e.each(a);
      0 === e.length && (d = clearInterval(d));
    }
    var f = 50,
      d,
      e = c();
    c.expr[':']['jquery-appear-jquery-appear-show'] = function(a) {
      return void 0 !== c(a).data('jquery-appear-jquery-appear-show');
    };
    c.fn._bindShow = function(a) {
      this.bind('jquery-appear-show', a);
      this.data(
        'jquery-appear-jquery-appear-show',
        'none' != this.css('display')
      );
      e = e.add(this);
      f && !d && (d = setInterval(b, f));
    };
    c.fn._unbindShow = function(a) {
      this.unbind('jquery-appear-show', a);
      this.removeData('jquery-appear-jquery-appear-show');
    };
    c.appear.setInterval = function(a) {
      a == f ||
        !c.isNumeric(a) ||
        0 > a ||
        ((f = a), (d = clearInterval(d)), 0 < f && (d = setInterval(b, f)));
    };
  })();
})(jQuery, window, document);
