/*! @flowjs/ng-flow 2.7.7 */
!(function(a, b, c) {
  'use strict';
  function d(b) {
    if (
      ((this.support = !(
        'undefined' == typeof File ||
        'undefined' == typeof Blob ||
        'undefined' == typeof FileList ||
        (!Blob.prototype.slice &&
          !Blob.prototype.webkitSlice &&
          !Blob.prototype.mozSlice)
      )),
      this.support)
    ) {
      (this.supportDirectory =
        /Chrome/.test(a.navigator.userAgent) ||
        /Firefox/.test(a.navigator.userAgent) ||
        /Edge/.test(a.navigator.userAgent)),
        (this.files = []),
        (this.defaults = {
          chunkSize: 1048576,
          forceChunkSize: !1,
          simultaneousUploads: 3,
          singleFile: !1,
          fileParameterName: 'file',
          progressCallbacksInterval: 500,
          speedSmoothingFactor: 0.1,
          query: {},
          headers: {},
          withCredentials: !1,
          preprocess: null,
          method: 'multipart',
          testMethod: 'GET',
          uploadMethod: 'POST',
          prioritizeFirstAndLastChunk: !1,
          allowDuplicateUploads: !1,
          target: '/',
          testChunks: !0,
          generateUniqueIdentifier: null,
          maxChunkRetries: 0,
          chunkRetryInterval: null,
          permanentErrors: [404, 413, 415, 500, 501],
          successStatuses: [200, 201, 202],
          onDropStopPropagation: !1,
          initFileFn: null,
          readFileFn: f,
        }),
        (this.opts = {}),
        (this.events = {});
      var c = this;
      (this.onDrop = function(a) {
        c.opts.onDropStopPropagation && a.stopPropagation(), a.preventDefault();
        var b = a.dataTransfer;
        b.items && b.items[0] && b.items[0].webkitGetAsEntry
          ? c.webkitReadDataTransfer(a)
          : c.addFiles(b.files, a);
      }),
        (this.preventEvent = function(a) {
          a.preventDefault();
        }),
        (this.opts = d.extend({}, this.defaults, b || {}));
    }
  }
  function e(a, b, d) {
    (this.flowObj = a),
      (this.bytes = null),
      (this.file = b),
      (this.name = b.fileName || b.name),
      (this.size = b.size),
      (this.relativePath = b.relativePath || b.webkitRelativePath || this.name),
      (this.uniqueIdentifier = d === c ? a.generateUniqueIdentifier(b) : d),
      (this.chunks = []),
      (this.paused = !1),
      (this.error = !1),
      (this.averageSpeed = 0),
      (this.currentSpeed = 0),
      (this._lastProgressCallback = Date.now()),
      (this._prevUploadedSize = 0),
      (this._prevProgress = 0),
      this.bootstrap();
  }
  function f(a, b, c, d, e) {
    var f = 'slice';
    a.file.slice
      ? (f = 'slice')
      : a.file.mozSlice
      ? (f = 'mozSlice')
      : a.file.webkitSlice && (f = 'webkitSlice'),
      e.readFinished(a.file[f](b, c, d));
  }
  function g(a, b, c) {
    (this.flowObj = a),
      (this.fileObj = b),
      (this.offset = c),
      (this.tested = !1),
      (this.retries = 0),
      (this.pendingRetry = !1),
      (this.preprocessState = 0),
      (this.readState = 0),
      (this.loaded = 0),
      (this.total = 0),
      (this.chunkSize = this.flowObj.opts.chunkSize),
      (this.startByte = this.offset * this.chunkSize),
      (this.computeEndByte = function() {
        var a = Math.min(this.fileObj.size, (this.offset + 1) * this.chunkSize);
        return (
          this.fileObj.size - a < this.chunkSize &&
            !this.flowObj.opts.forceChunkSize &&
            (a = this.fileObj.size),
          a
        );
      }),
      (this.endByte = this.computeEndByte()),
      (this.xhr = null);
    var d = this;
    (this.event = function(a, b) {
      (b = Array.prototype.slice.call(arguments)),
        b.unshift(d),
        d.fileObj.chunkEvent.apply(d.fileObj, b);
    }),
      (this.progressHandler = function(a) {
        a.lengthComputable && ((d.loaded = a.loaded), (d.total = a.total)),
          d.event('progress', a);
      }),
      (this.testHandler = function(a) {
        var b = d.status(!0);
        'error' === b
          ? (d.event(b, d.message()), d.flowObj.uploadNextChunk())
          : 'success' === b
          ? ((d.tested = !0),
            d.event(b, d.message()),
            d.flowObj.uploadNextChunk())
          : d.fileObj.paused || ((d.tested = !0), d.send());
      }),
      (this.doneHandler = function(a) {
        var b = d.status();
        if ('success' === b || 'error' === b)
          delete this.data,
            d.event(b, d.message()),
            d.flowObj.uploadNextChunk();
        else {
          d.event('retry', d.message()),
            (d.pendingRetry = !0),
            d.abort(),
            d.retries++;
          var c = d.flowObj.opts.chunkRetryInterval;
          null !== c
            ? setTimeout(function() {
                d.send();
              }, c)
            : d.send();
        }
      });
  }
  function h(a, b) {
    var c = a.indexOf(b);
    c > -1 && a.splice(c, 1);
  }
  function i(a, b) {
    return (
      'function' == typeof a &&
        ((b = Array.prototype.slice.call(arguments)),
        (a = a.apply(null, b.slice(1)))),
      a
    );
  }
  function j(a, b) {
    setTimeout(a.bind(b), 0);
  }
  function k(a, b) {
    return (
      l(arguments, function(b) {
        b !== a &&
          l(b, function(b, c) {
            a[c] = b;
          });
      }),
      a
    );
  }
  function l(a, b, c) {
    if (a) {
      var d;
      if ('undefined' != typeof a.length) {
        for (d = 0; d < a.length; d++) if (b.call(c, a[d], d) === !1) return;
      } else
        for (d in a)
          if (a.hasOwnProperty(d) && b.call(c, a[d], d) === !1) return;
    }
  }
  var m = a.navigator.msPointerEnabled;
  (d.prototype = {
    on: function(a, b) {
      (a = a.toLowerCase()),
        this.events.hasOwnProperty(a) || (this.events[a] = []),
        this.events[a].push(b);
    },
    off: function(a, b) {
      a !== c
        ? ((a = a.toLowerCase()),
          b !== c
            ? this.events.hasOwnProperty(a) && h(this.events[a], b)
            : delete this.events[a])
        : (this.events = {});
    },
    fire: function(a, b) {
      (b = Array.prototype.slice.call(arguments)), (a = a.toLowerCase());
      var c = !1;
      return (
        this.events.hasOwnProperty(a) &&
          l(
            this.events[a],
            function(a) {
              c = a.apply(this, b.slice(1)) === !1 || c;
            },
            this
          ),
        'catchall' != a &&
          (b.unshift('catchAll'), (c = this.fire.apply(this, b) === !1 || c)),
        !c
      );
    },
    webkitReadDataTransfer: function(a) {
      function b(a) {
        a.readEntries(function(f) {
          f.length
            ? ((g += f.length),
              l(f, function(a) {
                if (a.isFile) {
                  var e = a.fullPath;
                  a.file(function(a) {
                    c(a, e);
                  }, d);
                } else a.isDirectory && b(a.createReader());
              }),
              b(a))
            : e();
        }, d);
      }
      function c(a, b) {
        (a.relativePath = b.substring(1)), h.push(a), e();
      }
      function d(a) {
        throw a;
      }
      function e() {
        0 == --g && f.addFiles(h, a);
      }
      var f = this,
        g = a.dataTransfer.items.length,
        h = [];
      l(a.dataTransfer.items, function(a) {
        var d = a.webkitGetAsEntry();
        return d
          ? void (d.isFile ? c(a.getAsFile(), d.fullPath) : b(d.createReader()))
          : void e();
      });
    },
    generateUniqueIdentifier: function(a) {
      var b = this.opts.generateUniqueIdentifier;
      if ('function' == typeof b) return b(a);
      var c = a.relativePath || a.webkitRelativePath || a.fileName || a.name;
      return a.size + '-' + c.replace(/[^0-9a-zA-Z_-]/gim, '');
    },
    uploadNextChunk: function(a) {
      var b = !1;
      if (
        this.opts.prioritizeFirstAndLastChunk &&
        (l(this.files, function(a) {
          return !a.paused &&
            a.chunks.length &&
            'pending' === a.chunks[0].status()
            ? (a.chunks[0].send(), (b = !0), !1)
            : !a.paused &&
              a.chunks.length > 1 &&
              'pending' === a.chunks[a.chunks.length - 1].status()
            ? (a.chunks[a.chunks.length - 1].send(), (b = !0), !1)
            : void 0;
        }),
        b)
      )
        return b;
      if (
        (l(this.files, function(a) {
          return (
            a.paused ||
              l(a.chunks, function(a) {
                return 'pending' === a.status()
                  ? (a.send(), (b = !0), !1)
                  : void 0;
              }),
            b ? !1 : void 0
          );
        }),
        b)
      )
        return !0;
      var c = !1;
      return (
        l(this.files, function(a) {
          return a.isComplete() ? void 0 : ((c = !0), !1);
        }),
        c ||
          a ||
          j(function() {
            this.fire('complete');
          }, this),
        !1
      );
    },
    assignBrowse: function(a, c, d, e) {
      a instanceof Element && (a = [a]),
        l(
          a,
          function(a) {
            var f;
            'INPUT' === a.tagName && 'file' === a.type
              ? (f = a)
              : ((f = b.createElement('input')),
                f.setAttribute('type', 'file'),
                k(f.style, {
                  visibility: 'hidden',
                  position: 'absolute',
                  width: '1px',
                  height: '1px',
                }),
                a.appendChild(f),
                a.addEventListener(
                  'click',
                  function() {
                    f.click();
                  },
                  !1
                )),
              this.opts.singleFile ||
                d ||
                f.setAttribute('multiple', 'multiple'),
              c && f.setAttribute('webkitdirectory', 'webkitdirectory'),
              l(e, function(a, b) {
                f.setAttribute(b, a);
              });
            var g = this;
            f.addEventListener(
              'change',
              function(a) {
                a.target.value &&
                  (g.addFiles(a.target.files, a), (a.target.value = ''));
              },
              !1
            );
          },
          this
        );
    },
    assignDrop: function(a) {
      'undefined' == typeof a.length && (a = [a]),
        l(
          a,
          function(a) {
            a.addEventListener('dragover', this.preventEvent, !1),
              a.addEventListener('dragenter', this.preventEvent, !1),
              a.addEventListener('drop', this.onDrop, !1);
          },
          this
        );
    },
    unAssignDrop: function(a) {
      'undefined' == typeof a.length && (a = [a]),
        l(
          a,
          function(a) {
            a.removeEventListener('dragover', this.preventEvent),
              a.removeEventListener('dragenter', this.preventEvent),
              a.removeEventListener('drop', this.onDrop);
          },
          this
        );
    },
    isUploading: function() {
      var a = !1;
      return (
        l(this.files, function(b) {
          return b.isUploading() ? ((a = !0), !1) : void 0;
        }),
        a
      );
    },
    _shouldUploadNext: function() {
      var a = 0,
        b = !0,
        c = this.opts.simultaneousUploads;
      return (
        l(this.files, function(d) {
          l(d.chunks, function(d) {
            return 'uploading' === d.status() && (a++, a >= c)
              ? ((b = !1), !1)
              : void 0;
          });
        }),
        b && a
      );
    },
    upload: function() {
      var a = this._shouldUploadNext();
      if (a !== !1) {
        this.fire('uploadStart');
        for (var b = !1, c = 1; c <= this.opts.simultaneousUploads - a; c++)
          b = this.uploadNextChunk(!0) || b;
        b ||
          j(function() {
            this.fire('complete');
          }, this);
      }
    },
    resume: function() {
      l(this.files, function(a) {
        a.isComplete() || a.resume();
      });
    },
    pause: function() {
      l(this.files, function(a) {
        a.pause();
      });
    },
    cancel: function() {
      for (var a = this.files.length - 1; a >= 0; a--) this.files[a].cancel();
    },
    progress: function() {
      var a = 0,
        b = 0;
      return (
        l(this.files, function(c) {
          (a += c.progress() * c.size), (b += c.size);
        }),
        b > 0 ? a / b : 0
      );
    },
    addFile: function(a, b) {
      this.addFiles([a], b);
    },
    addFiles: function(a, b) {
      var c = [];
      l(
        a,
        function(a) {
          if (
            (!m || (m && a.size > 0)) &&
            (a.size % 4096 !== 0 || ('.' !== a.name && '.' !== a.fileName))
          ) {
            var d = this.generateUniqueIdentifier(a);
            if (
              this.opts.allowDuplicateUploads ||
              !this.getFromUniqueIdentifier(d)
            ) {
              var f = new e(this, a, d);
              this.fire('fileAdded', f, b) && c.push(f);
            }
          }
        },
        this
      ),
        this.fire('filesAdded', c, b) &&
          (l(
            c,
            function(a) {
              this.opts.singleFile &&
                this.files.length > 0 &&
                this.removeFile(this.files[0]),
                this.files.push(a);
            },
            this
          ),
          this.fire('filesSubmitted', c, b));
    },
    removeFile: function(a) {
      for (var b = this.files.length - 1; b >= 0; b--)
        this.files[b] === a &&
          (this.files.splice(b, 1), a.abort(), this.fire('fileRemoved', a));
    },
    getFromUniqueIdentifier: function(a) {
      var b = !1;
      return (
        l(this.files, function(c) {
          c.uniqueIdentifier === a && (b = c);
        }),
        b
      );
    },
    getSize: function() {
      var a = 0;
      return (
        l(this.files, function(b) {
          a += b.size;
        }),
        a
      );
    },
    sizeUploaded: function() {
      var a = 0;
      return (
        l(this.files, function(b) {
          a += b.sizeUploaded();
        }),
        a
      );
    },
    timeRemaining: function() {
      var a = 0,
        b = 0;
      return (
        l(this.files, function(c) {
          c.paused ||
            c.error ||
            ((a += c.size - c.sizeUploaded()), (b += c.averageSpeed));
        }),
        a && !b ? Number.POSITIVE_INFINITY : a || b ? Math.floor(a / b) : 0
      );
    },
  }),
    (e.prototype = {
      measureSpeed: function() {
        var a = Date.now() - this._lastProgressCallback;
        if (a) {
          var b = this.flowObj.opts.speedSmoothingFactor,
            c = this.sizeUploaded();
          (this.currentSpeed = Math.max(
            ((c - this._prevUploadedSize) / a) * 1e3,
            0
          )),
            (this.averageSpeed =
              b * this.currentSpeed + (1 - b) * this.averageSpeed),
            (this._prevUploadedSize = c);
        }
      },
      chunkEvent: function(a, b, c) {
        switch (b) {
          case 'progress':
            if (
              Date.now() - this._lastProgressCallback <
              this.flowObj.opts.progressCallbacksInterval
            )
              break;
            this.measureSpeed(),
              this.flowObj.fire('fileProgress', this, a),
              this.flowObj.fire('progress'),
              (this._lastProgressCallback = Date.now());
            break;
          case 'error':
            (this.error = !0),
              this.abort(!0),
              this.flowObj.fire('fileError', this, c, a),
              this.flowObj.fire('error', c, this, a);
            break;
          case 'success':
            if (this.error) return;
            this.measureSpeed(),
              this.flowObj.fire('fileProgress', this, a),
              this.flowObj.fire('progress'),
              (this._lastProgressCallback = Date.now()),
              this.isComplete() &&
                ((this.currentSpeed = 0),
                (this.averageSpeed = 0),
                this.flowObj.fire('fileSuccess', this, c, a));
            break;
          case 'retry':
            this.flowObj.fire('fileRetry', this, a);
        }
      },
      pause: function() {
        (this.paused = !0), this.abort();
      },
      resume: function() {
        (this.paused = !1), this.flowObj.upload();
      },
      abort: function(a) {
        (this.currentSpeed = 0), (this.averageSpeed = 0);
        var b = this.chunks;
        a && (this.chunks = []),
          l(
            b,
            function(a) {
              'uploading' === a.status() &&
                (a.abort(), this.flowObj.uploadNextChunk());
            },
            this
          );
      },
      cancel: function() {
        this.flowObj.removeFile(this);
      },
      retry: function() {
        this.bootstrap(), this.flowObj.upload();
      },
      bootstrap: function() {
        'function' == typeof this.flowObj.opts.initFileFn &&
          this.flowObj.opts.initFileFn(this),
          this.abort(!0),
          (this.error = !1),
          (this._prevProgress = 0);
        for (
          var a = this.flowObj.opts.forceChunkSize ? Math.ceil : Math.floor,
            b = Math.max(a(this.size / this.flowObj.opts.chunkSize), 1),
            c = 0;
          b > c;
          c++
        )
          this.chunks.push(new g(this.flowObj, this, c));
      },
      progress: function() {
        if (this.error) return 1;
        if (1 === this.chunks.length)
          return (
            (this._prevProgress = Math.max(
              this._prevProgress,
              this.chunks[0].progress()
            )),
            this._prevProgress
          );
        var a = 0;
        l(this.chunks, function(b) {
          a += b.progress() * (b.endByte - b.startByte);
        });
        var b = a / this.size;
        return (
          (this._prevProgress = Math.max(
            this._prevProgress,
            b > 0.9999 ? 1 : b
          )),
          this._prevProgress
        );
      },
      isUploading: function() {
        var a = !1;
        return (
          l(this.chunks, function(b) {
            return 'uploading' === b.status() ? ((a = !0), !1) : void 0;
          }),
          a
        );
      },
      isComplete: function() {
        var a = !1;
        return (
          l(this.chunks, function(b) {
            var c = b.status();
            return 'pending' === c ||
              'uploading' === c ||
              'reading' === c ||
              1 === b.preprocessState ||
              1 === b.readState
              ? ((a = !0), !1)
              : void 0;
          }),
          !a
        );
      },
      sizeUploaded: function() {
        var a = 0;
        return (
          l(this.chunks, function(b) {
            a += b.sizeUploaded();
          }),
          a
        );
      },
      timeRemaining: function() {
        if (this.paused || this.error) return 0;
        var a = this.size - this.sizeUploaded();
        return a && !this.averageSpeed
          ? Number.POSITIVE_INFINITY
          : a || this.averageSpeed
          ? Math.floor(a / this.averageSpeed)
          : 0;
      },
      getType: function() {
        return this.file.type && this.file.type.split('/')[1];
      },
      getExtension: function() {
        return this.name
          .substr((~-this.name.lastIndexOf('.') >>> 0) + 2)
          .toLowerCase();
      },
    }),
    (g.prototype = {
      getParams: function() {
        return {
          flowChunkNumber: this.offset + 1,
          flowChunkSize: this.flowObj.opts.chunkSize,
          flowCurrentChunkSize: this.endByte - this.startByte,
          flowTotalSize: this.fileObj.size,
          flowIdentifier: this.fileObj.uniqueIdentifier,
          flowFilename: this.fileObj.name,
          flowRelativePath: this.fileObj.relativePath,
          flowTotalChunks: this.fileObj.chunks.length,
        };
      },
      getTarget: function(a, b) {
        return (a += a.indexOf('?') < 0 ? '?' : '&'), a + b.join('&');
      },
      test: function() {
        (this.xhr = new XMLHttpRequest()),
          this.xhr.addEventListener('load', this.testHandler, !1),
          this.xhr.addEventListener('error', this.testHandler, !1);
        var a = i(this.flowObj.opts.testMethod, this.fileObj, this),
          b = this.prepareXhrRequest(a, !0);
        this.xhr.send(b);
      },
      preprocessFinished: function() {
        (this.endByte = this.computeEndByte()),
          (this.preprocessState = 2),
          this.send();
      },
      readFinished: function(a) {
        (this.readState = 2), (this.bytes = a), this.send();
      },
      send: function() {
        var a = this.flowObj.opts.preprocess,
          b = this.flowObj.opts.readFileFn;
        if ('function' == typeof a)
          switch (this.preprocessState) {
            case 0:
              return (this.preprocessState = 1), void a(this);
            case 1:
              return;
          }
        switch (this.readState) {
          case 0:
            return (
              (this.readState = 1),
              void b(
                this.fileObj,
                this.startByte,
                this.endByte,
                this.fileObj.file.type,
                this
              )
            );
          case 1:
            return;
        }
        if (this.flowObj.opts.testChunks && !this.tested)
          return void this.test();
        (this.loaded = 0),
          (this.total = 0),
          (this.pendingRetry = !1),
          (this.xhr = new XMLHttpRequest()),
          this.xhr.upload.addEventListener(
            'progress',
            this.progressHandler,
            !1
          ),
          this.xhr.addEventListener('load', this.doneHandler, !1),
          this.xhr.addEventListener('error', this.doneHandler, !1);
        var c = i(this.flowObj.opts.uploadMethod, this.fileObj, this),
          d = this.prepareXhrRequest(
            c,
            !1,
            this.flowObj.opts.method,
            this.bytes
          );
        this.xhr.send(d);
      },
      abort: function() {
        var a = this.xhr;
        (this.xhr = null), a && a.abort();
      },
      status: function(a) {
        return 1 === this.readState
          ? 'reading'
          : this.pendingRetry || 1 === this.preprocessState
          ? 'uploading'
          : this.xhr
          ? this.xhr.readyState < 4
            ? 'uploading'
            : this.flowObj.opts.successStatuses.indexOf(this.xhr.status) > -1
            ? 'success'
            : this.flowObj.opts.permanentErrors.indexOf(this.xhr.status) > -1 ||
              (!a && this.retries >= this.flowObj.opts.maxChunkRetries)
            ? 'error'
            : (this.abort(), 'pending')
          : 'pending';
      },
      message: function() {
        return this.xhr ? this.xhr.responseText : '';
      },
      progress: function() {
        if (this.pendingRetry) return 0;
        var a = this.status();
        return 'success' === a || 'error' === a
          ? 1
          : 'pending' === a
          ? 0
          : this.total > 0
          ? this.loaded / this.total
          : 0;
      },
      sizeUploaded: function() {
        var a = this.endByte - this.startByte;
        return 'success' !== this.status() && (a = this.progress() * a), a;
      },
      prepareXhrRequest: function(a, b, c, d) {
        var e = i(this.flowObj.opts.query, this.fileObj, this, b);
        e = k(e, this.getParams());
        var f = i(this.flowObj.opts.target, this.fileObj, this, b),
          g = null;
        if ('GET' === a || 'octet' === c) {
          var h = [];
          l(e, function(a, b) {
            h.push([encodeURIComponent(b), encodeURIComponent(a)].join('='));
          }),
            (f = this.getTarget(f, h)),
            (g = d || null);
        } else
          (g = new FormData()),
            l(e, function(a, b) {
              g.append(b, a);
            }),
            'undefined' != typeof d &&
              g.append(
                this.flowObj.opts.fileParameterName,
                d,
                this.fileObj.file.name
              );
        return (
          this.xhr.open(a, f, !0),
          (this.xhr.withCredentials = this.flowObj.opts.withCredentials),
          l(
            i(this.flowObj.opts.headers, this.fileObj, this, b),
            function(a, b) {
              this.xhr.setRequestHeader(b, a);
            },
            this
          ),
          g
        );
      },
    }),
    (d.evalOpts = i),
    (d.extend = k),
    (d.each = l),
    (d.FlowFile = e),
    (d.FlowChunk = g),
    (d.version = '2.13.0'),
    'object' == typeof module && module && 'object' == typeof module.exports
      ? (module.exports = d)
      : ((a.Flow = d),
        'function' == typeof define &&
          define.amd &&
          define('flow', [], function() {
            return d;
          }));
})(window, document),
  angular.module('flow.provider', []).provider('flowFactory', function() {
    'use strict';
    (this.defaults = {}),
      (this.factory = function(a) {
        return new Flow(a);
      }),
      (this.events = []),
      (this.on = function(a, b) {
        this.events.push([a, b]);
      }),
      (this.$get = function() {
        var a = this.factory,
          b = this.defaults,
          c = this.events;
        return {
          create: function(d) {
            var e = a(angular.extend({}, b, d));
            return (
              angular.forEach(c, function(a) {
                e.on(a[0], a[1]);
              }),
              e
            );
          },
        };
      });
  }),
  angular
    .module('flow.init', ['flow.provider'])
    .controller('flowCtrl', [
      '$scope',
      '$attrs',
      '$parse',
      'flowFactory',
      function(a, b, c, d) {
        var e = angular.extend({}, a.$eval(b.flowInit)),
          f = a.$eval(b.flowObject) || d.create(e),
          g = function(b) {
            var c = Array.prototype.slice.call(arguments);
            c.shift();
            var d = a.$broadcast.apply(a, ['flow::' + b, f].concat(c));
            return (
              {
                progress: 1,
                filesSubmitted: 1,
                fileSuccess: 1,
                fileError: 1,
                complete: 1,
              }[b] && a.$apply(),
              d.defaultPrevented ? !1 : void 0
            );
          };
        f.on('catchAll', g),
          a.$on('$destroy', function() {
            f.off('catchAll', g);
          }),
          (a.$flow = f),
          b.hasOwnProperty('flowName') &&
            (c(b.flowName).assign(a, f),
            a.$on('$destroy', function() {
              c(b.flowName).assign(a);
            }));
      },
    ])
    .directive('flowInit', [
      function() {
        return { scope: !0, controller: 'flowCtrl' };
      },
    ]),
  angular.module('flow.btn', ['flow.init']).directive('flowBtn', [
    function() {
      return {
        restrict: 'EA',
        scope: !1,
        require: '^flowInit',
        link: function(a, b, c) {
          var d = c.hasOwnProperty('flowDirectory'),
            e = c.hasOwnProperty('flowSingleFile'),
            f = c.hasOwnProperty('flowAttrs') && a.$eval(c.flowAttrs);
          a.$flow.assignBrowse(b, d, e, f);
        },
      };
    },
  ]),
  angular
    .module('flow.dragEvents', ['flow.init'])
    .directive('flowPreventDrop', function() {
      return {
        scope: !1,
        link: function(a, b, c) {
          b.bind('drop dragover', function(a) {
            a.preventDefault();
          });
        },
      };
    })
    .directive('flowDragEnter', [
      '$timeout',
      function(a) {
        return {
          scope: !1,
          link: function(b, c, d) {
            function e(a) {
              var b = !1,
                c = a.dataTransfer || a.originalEvent.dataTransfer;
              return (
                angular.forEach(c && c.types, function(a) {
                  'Files' === a && (b = !0);
                }),
                b
              );
            }
            var f,
              g = !1;
            c.bind('dragover', function(c) {
              e(c) &&
                (g || (b.$apply(d.flowDragEnter), (g = !0)),
                a.cancel(f),
                c.preventDefault());
            }),
              c.bind('dragleave drop', function(c) {
                a.cancel(f),
                  (f = a(function() {
                    b.$eval(d.flowDragLeave), (f = null), (g = !1);
                  }, 100));
              });
          },
        };
      },
    ]),
  angular.module('flow.drop', ['flow.init']).directive('flowDrop', function() {
    return {
      scope: !1,
      require: '^flowInit',
      link: function(a, b, c) {
        function d() {
          a.$flow.assignDrop(b);
        }
        function e() {
          a.$flow.unAssignDrop(b);
        }
        c.flowDropEnabled
          ? a.$watch(c.flowDropEnabled, function(a) {
              a ? d() : e();
            })
          : d();
      },
    };
  }),
  !(function(a) {
    'use strict';
    function b(a) {
      return a.charAt(0).toUpperCase() + a.slice(1);
    }
    var c = a.module('flow.events', ['flow.init']),
      d = {
        fileSuccess: ['$file', '$message'],
        fileProgress: ['$file'],
        fileAdded: ['$file', '$event'],
        filesAdded: ['$files', '$event'],
        filesSubmitted: ['$files', '$event'],
        fileRetry: ['$file'],
        fileRemoved: ['$file'],
        fileError: ['$file', '$message'],
        uploadStart: [],
        complete: [],
        progress: [],
        error: ['$message', '$file'],
      };
    a.forEach(d, function(d, e) {
      var f = 'flow' + b(e);
      'flowUploadStart' == f && (f = 'flowUploadStarted'),
        c.directive(f, [
          function() {
            return {
              require: '^flowInit',
              controller: [
                '$scope',
                '$attrs',
                function(b, c) {
                  b.$on('flow::' + e, function() {
                    var e = Array.prototype.slice.call(arguments),
                      g = e.shift();
                    if (b.$flow === e.shift()) {
                      var h = {};
                      a.forEach(d, function(a, b) {
                        h[a] = e[b];
                      }),
                        b.$eval(c[f], h) === !1 && g.preventDefault();
                    }
                  });
                },
              ],
            };
          },
        ]);
    });
  })(angular),
  angular.module('flow.img', ['flow.init']).directive('flowImg', [
    function() {
      return {
        scope: !1,
        require: '^flowInit',
        link: function(a, b, c) {
          var d = c.flowImg;
          a.$watch(d, function(b) {
            if (b) {
              var d = new FileReader();
              d.readAsDataURL(b.file),
                (d.onload = function(b) {
                  a.$apply(function() {
                    c.$set('src', b.target.result);
                  });
                });
            }
          });
        },
      };
    },
  ]),
  angular.module('flow.transfers', ['flow.init']).directive('flowTransfers', [
    function() {
      return {
        scope: !0,
        require: '^flowInit',
        link: function(a) {
          a.transfers = a.$flow.files;
        },
      };
    },
  ]),
  angular.module('flow', [
    'flow.provider',
    'flow.init',
    'flow.events',
    'flow.btn',
    'flow.drop',
    'flow.transfers',
    'flow.img',
    'flow.dragEvents',
  ]);
