/**
 * [angular-appear]{@link https://github.com/emn178/angular-appear}
 *
 * @version 0.1.2
 * @author Yi-Cyuan Chen [emn178@gmail.com]
 * @copyright Yi-Cyuan Chen 2014-2016
 * @license MIT
 */
(function(e, a) {
  var f = '',
    d = e.module('ngAppear', []);
  ['appear', 'disappear', 'appearing'].forEach(function(c) {
    var a = 'ng' + c.substring(0, 1).toUpperCase() + c.substring(1);
    d.directive(a, [
      '$parse',
      function(b) {
        return {
          restrict: 'A',
          compile: function(e, d) {
            var g = b(d[a]);
            return function(a, b) {
              b.on(f + c, function(c) {
                a.$apply(function() {
                  g(a, { $event: c });
                });
              });
            };
          },
        };
      },
    ]);
  });
  var b = a.appear.setEventPrefix;
  b &&
    (a.appear.setEventPrefix = function(a) {
      f = a;
      b(a);
    });
})(angular, jQuery);
